import gql from 'graphql-tag'
import TagAllFields from './fragments/TagAllFields'

export default gql`
  mutation createEventTag ($event_id: Int!, $value: EventTagInput!) {
    createEventTag (event_id: $event_id, value: $value) {
      ...TagAllFields
    }
  }
  ${TagAllFields}
`
