import gql from 'graphql-tag'
import EventAllFields from './fragments/EventAllFields'

export default gql`
  query getEvents {
    events {
      ...EventAllFields
    }
  }
  ${EventAllFields}
`
