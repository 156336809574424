import gql from 'graphql-tag'
import EventAllFields from './fragments/EventAllFields'

export default gql`
  mutation createEvent($value: EventInput!) {
    createEvent (value: $value) {
      ...EventAllFields
    }
  }
  ${EventAllFields}
`
