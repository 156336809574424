import gql from 'graphql-tag'
import CommentResolutionAllFields from './fragments/CommentResolutionAllFields'

export default gql`
  mutation updateEventCommentResolution ($id: Int!, $value: EventCommentResolutionUpdateInput!) {
    updateEventCommentResolution (id: $id, value: $value) {
      ...CommentResolutionAllFields
    }
  }
  ${CommentResolutionAllFields}
`
