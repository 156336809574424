import gql from 'graphql-tag'
import TagAllFields from './fragments/TagAllFields'

export default gql`
  mutation updateEventTag ($id: Int!, $value: EventTagInput!) {
    updateEventTag (id: $id, value: $value) {
      ...TagAllFields
    }
  }
  ${TagAllFields}
`
