import gql from 'graphql-tag'
import CommentResolutionAllFields from './fragments/CommentResolutionAllFields'

export default gql`
  mutation createEventCommentResolution ($event_id: Int!, $value: EventCommentResolutionCreateInput!) {
    createEventCommentResolution (event_id: $event_id, value: $value) {
      ...CommentResolutionAllFields
    }
  }
  ${CommentResolutionAllFields}
`
