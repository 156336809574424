import gql from 'graphql-tag'
import TagAllFields from './fragments/TagAllFields'

export default gql`
  query getEventTags {
    eventTags {
      ...TagAllFields
    }
  }
  ${TagAllFields}
`
