import gql from 'graphql-tag'
import CommentResolutionAllFields from './fragments/CommentResolutionAllFields'

export default gql`
  query getEventCommentResolutions {
    commentResolutions {
      ...CommentResolutionAllFields
    }
  }
  ${CommentResolutionAllFields}
`
