<template>
  <v-dialog
    width="1540"
    :value="value"
    scrollable
    @input="$emit('input', $event)"
  >
    <template
      v-if="activator"
      v-slot:activator="{ on, attrs }"
    >
      <v-btn
        small
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small>
          mdi-plus-circle-outline
        </v-icon>
      </v-btn>
    </template>
    <v-card height="100%">
      <v-toolbar
        max-height="64"
        color="primary"
        dark
        flat
      >
        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
        <template v-if="buttons.length">
          <v-spacer />
          <v-btn
            v-for="(button, index) in buttons"
            :key="index"
            :to="button.to"
            icon
          >
            <v-icon>
              {{ button.icon }}
            </v-icon>
          </v-btn>
        </template>
      </v-toolbar>
      <v-divider />
      <v-card-text
        class="pa-0"
        :style="{ 'overflow-y': 'scroll' }"
      >
        <template v-if="tabs.length > 1">
          <v-tabs v-model="currentTab">
            <v-tab
              v-for="tab in tabs"
              :key="tab.name"
            >
              {{ tab.name }}
            </v-tab>
          </v-tabs>
          <v-divider />
          <div
            :class="{ 'pa-4': !dense, 'px-2': dense }"
          >
            <v-tabs-items v-model="currentTab">
              <v-tab-item
                v-for="tab in tabs"
                :key="tab.name"
              >
                <component
                  :is="tab.component"
                  v-if="dialogIsOpen"
                  v-bind="tab.props"
                  @input="$event => $emit('input', $event)"
                  @mounted="$event => $emit('mounted', $event)"
                />
              </v-tab-item>
            </v-tabs-items>
          </div>
        </template>
        <template v-else-if="tabs.length === 1">
          <div
            class="fill-height"
            :class="{ 'pa-4': !dense, 'px-2': dense }"
          >
            <component
              :is="tabs[0].component"
              v-if="dialogIsOpen"
              v-bind="tabs[0].props"
              @input="$event => $emit('input', $event)"
              @mounted="$event => $emit('mounted', $event)"
            />
          </div>
        </template>
        <template v-else>
          <div
            :class="{ 'pa-4': !dense, 'px-2': dense }"
          />
        </template>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          depressed
          @click.native="$emit('input', false)"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ExampleDialog',
  props: {
    title: {
      type: String,
      default: ''
    },
    activator: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    tabs: {
      type: Array,
      default: () => ([])
    },
    buttons: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    currentTab: 0,
    dialogIsOpen: false
  }),
  computed: {
  },
  watch: {
    value (newValue) {
      this.dialogIsOpen = newValue
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style lang="scss">
  .v-dialog--scrollable {
    height: 90%;
    .v-card {
      height: 100% !important;
    }
  }
</style>
