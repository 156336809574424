import gql from 'graphql-tag'
import MilestoneAllFields from './fragments/MilestoneAllFields'

export default gql`
  mutation createEventMilestone ($event_id: Int!, $value: EventMilestoneInput!) {
    createEventMilestone (event_id: $event_id, value: $value) {
      ...MilestoneAllFields
    }
  }
  ${MilestoneAllFields}
`
