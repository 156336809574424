import gql from 'graphql-tag'
import MilestoneAllFields from './fragments/MilestoneAllFields'

export default gql`
  mutation updateEventMilestone ($id: Int!, $value: EventMilestoneInput!) {
    updateEventMilestone (id: $id, value: $value) {
      ...MilestoneAllFields
    }
  }
  ${MilestoneAllFields}
`
