import gql from 'graphql-tag'
import UserAllFields from './fragments/UserAllFields'

export default gql`
  mutation createEventModerator ($event_id: Int!, $value: EventModeratorInput!) {
    createEventModerator (event_id: $event_id, value: $value) {
      id
      user {
        ...UserAllFields
      }
    }
  }
  ${UserAllFields}
`
