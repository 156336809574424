import gql from 'graphql-tag'
import CommentCategoryAllFields from './fragments/CommentCategoryAllFields'

export default gql`
  query getEventCommentCategories {
    commentCategories {
      ...CommentCategoryAllFields
    }
  }
  ${CommentCategoryAllFields}
`
