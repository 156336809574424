import gql from 'graphql-tag'
import UserAllFields from './fragments/UserAllFields'

export default gql`
  query getUsers {
    users {
      ...UserAllFields
    }
  }
  ${UserAllFields}
`
