import gql from 'graphql-tag'
import CommentCategoryAllFields from './fragments/CommentCategoryAllFields'

export default gql`
  mutation updateEventCommentCategory ($id: Int!, $value: EventCommentCategoryUpdateInput!) {
    updateEventCommentCategory (id: $id, value: $value) {
      ...CommentCategoryAllFields
    }
  }
  ${CommentCategoryAllFields}
`
