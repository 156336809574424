<template>
  <v-card outlined>
    <v-card-text>
      <v-row dense>
        <v-col>
          <v-combobox
            v-model="selectedResolution"
            label="Resolutions"
            item-value="id"
            item-text="title"
            :items="commentResolutions.filter(resolution => !event.commentResolutions.map(resolution => resolution.id).includes(resolution.id))"
            :loading="$apollo.queries.commentResolutions.loading || loading.create"
            :disabled="$apollo.queries.commentResolutions.loading || loading.create"
            return-object
            hide-details
            clearable
            outlined
            @change="createMutation"
          />
        </v-col>
      </v-row>
      <v-row
        class="mb-4"
        dense
      >
        <v-col>
          <v-text-field
            v-model="filterString"
            label="Filter"
            hide-details
            clearable
            outlined
          />
        </v-col>
        <v-col>
          <v-select
            v-model="selectedOrder"
            label="Order"
            :items="['title', 'created']"
            hide-details
            outlined
          />
        </v-col>
        <v-col class="d-flex align-center flex-grow-0">
          <v-btn
            icon
            @click="selectedOrderDirection = !selectedOrderDirection"
          >
            <v-icon>
              {{ selectedOrderDirection ? 'mdi-sort-ascending' : 'mdi-sort-descending' }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <manage-event-comment-resolutions-item
            v-for="resolution of orderedList"
            :key="resolution.id"
            ref="resolution"
            :event="event"
            :item="resolution"
            @input="updateDirtyAvailable"
          />
          <v-alert
            v-if="!event.commentResolutions.length"
            class="mb-0"
            color="grey lighten-1"
            dark
          >
            <div class="text-h6">
              The list is empty.
            </div>
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-btn
        color="blue"
        :disabled="!dirtyAvailable"
        depressed
        @click="updateAll"
      >
        Save All
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ManageEventCommentResolutionsItem from './ManageEventCommentResolutionsItem'
export default {
  name: 'ManageEventCommentResolutions',
  components: { ManageEventCommentResolutionsItem },
  props: {
    event: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    commentResolutions: [],
    selectedResolution: null,
    selectedOrder: 'created',
    selectedOrderDirection: false,
    filterString: '',
    loading: {
      create: false
    },
    dirtyAvailable: false
  }),
  computed: {
    filteredList () {
      if (!this.filterString) return this.event.commentResolutions
      return this.event.commentResolutions.filter(resolution => resolution.title.toLowerCase().includes(this.filterString))
    },
    orderedList () {
      return this._.orderBy(this.filteredList, [this.selectedOrder], [this.selectedOrderDirection ? 'asc' : 'desc'])
    }
  },
  methods: {
    async createMutation (input) {
      if (!input) return
      this.loading.create = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/createEventCommentResolution').default,
          variables: { event_id: this.event.id, value: { title: typeof input === 'object' ? input.title : input } },
          update: (store, { data: { createEventCommentResolution } }) => {
            const data = store.readQuery({ query: require('@/gql/getEvents').default })
            const currentlyCachedEvent = this._.find(data.events, { id: this.event.id })
            currentlyCachedEvent.commentResolutions = this._.unionBy(currentlyCachedEvent.commentResolutions, [createEventCommentResolution], 'id')
            store.writeQuery({ query: require('@/gql/getEvents').default, data })
          }
        })
        this.selectedResolution = null
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'GraphQL Error',
          text: error.message.replace('GraphQL error: ', ''),
          duration: 10000
        })
      }
      this.loading.create = false
    },
    updateDirtyAvailable () {
      if (!this.$refs.resolution) this.dirtyAvailable = false
      else this.dirtyAvailable = this._.some(this.$refs.resolution.map(ref => ref.isDirty))
    },
    updateAll () {
      if (!this.$refs.resolution) return
      for (const ref of this.$refs.resolution) {
        if (ref.isDirty) ref.updateMutation()
      }
    }
  },
  apollo: {
    commentResolutions: {
      query: require('@/gql/getEventCommentResolutions').default
    }
  }
}
</script>
