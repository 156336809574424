<template>
  <v-card outlined>
    <v-card-text>
      <v-row dense>
        <v-col>
          <v-combobox
            v-model="selectedTag"
            label="Tags"
            item-value="id"
            item-text="display_name"
            :items="_.uniqBy(eventTags, 'name').filter(tag => !event.tags.map(tag => tag.name).includes(tag.name))"
            :loading="$apollo.queries.eventTags.loading || loading.create"
            :disabled="$apollo.queries.eventTags.loading || loading.create"
            return-object
            hide-details
            clearable
            outlined
            @change="createMutation"
          />
        </v-col>
      </v-row>
      <v-row
        class="mb-4"
        dense
      >
        <v-col>
          <v-text-field
            v-model="filterString"
            label="Filter"
            hide-details
            clearable
            outlined
          />
        </v-col>
        <v-col>
          <v-select
            v-model="selectedOrder"
            label="Order"
            :items="availableOrders"
            hide-details
            outlined
          />
        </v-col>
        <v-col class="d-flex align-center flex-grow-0">
          <v-btn
            icon
            @click="selectedOrderDirection = !selectedOrderDirection"
          >
            <v-icon>
              {{ selectedOrderDirection ? 'mdi-sort-ascending' : 'mdi-sort-descending' }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <manage-event-tags-item
            v-for="tag of orderedList"
            :key="tag.id"
            ref="tag"
            :event="event"
            :item="tag"
            @input="updateDirtyAvailable"
          />
          <v-alert
            v-if="!event.tags.length"
            class="mb-0"
            color="grey lighten-1"
            dark
          >
            <div class="text-h6">
              The list is empty.
            </div>
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-btn
        color="blue"
        :disabled="!dirtyAvailable"
        depressed
        @click="updateAll"
      >
        Save All
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ManageEventTagsItem from './ManageEventTagsItem'
export default {
  name: 'ManageEventTags',
  components: { ManageEventTagsItem },
  props: {
    event: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    eventTags: [],
    selectedTag: null,
    selectedOrder: 'created',
    availableOrders: ['name', 'display_name', 'created'],
    selectedOrderDirection: false,
    filterString: '',
    loading: {
      create: false
    },
    dirtyAvailable: false
  }),
  computed: {
    filteredList () {
      if (!this.filterString) return this.event.tags
      const string = this.filterString.toLowerCase()
      console.log('Filtering', string)
      return this.event.tags.filter(tag => tag.name.includes(string) || tag.display_name.toLowerCase().includes(string))
    },
    orderedList () {
      console.log('Ordering', this.selectedOrder, this.selectedOrderDirection ? 'asc' : 'desc')
      return this._.orderBy(this.filteredList, [this.selectedOrder], [this.selectedOrderDirection ? 'asc' : 'desc'])
    }
  },
  methods: {
    async createMutation (input) {
      if (!input) return
      this.loading.create = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/createEventTag').default,
          variables: { event_id: this.event.id, value: { display_name: typeof input === 'object' ? input.display_name : input } },
          update: (store, { data: { createEventTag } }) => {
            const data = store.readQuery({ query: require('@/gql/getEvents').default })
            const currentlyCachedEvent = this._.find(data.events, { id: this.event.id })
            currentlyCachedEvent.tags = this._.unionBy(currentlyCachedEvent.tags, [createEventTag], 'id')
            store.writeQuery({ query: require('@/gql/getEvents').default, data })
          }
        })
        this.selectedTag = null
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'GraphQL Error',
          text: error.message.replace('GraphQL error: ', ''),
          duration: 10000
        })
      }
      this.loading.create = false
    },
    updateDirtyAvailable () {
      if (!this.$refs.tag) this.dirtyAvailable = false
      else this.dirtyAvailable = this._.some(this.$refs.tag.map(ref => ref.isDirty))
    },
    updateAll () {
      if (!this.$refs.tag) return
      for (const ref of this.$refs.tag) {
        if (ref.isDirty) ref.updateMutation()
      }
    }
  },
  apollo: {
    eventTags: {
      query: require('@/gql/getEventTags').default
    }
  }
}
</script>
