<template>
  <v-card outlined>
    <v-card-text>
      <v-form
        ref="form"
        v-model="formValid"
      >
        <v-alert
          type="warning"
          prominent
        >
          <div class="text-h6">
            This window is used to create new <strong>Events</strong>, not <strong>Projects</strong>, if you are a participant in an <strong>Event</strong> create a new <strong>Project</strong> by closing this window, selecting an <strong>Event</strong> on the left and clicking <strong>New Project</strong> on the right.
          </div>
        </v-alert>
        <v-row dense>
          <v-col>
            <v-text-field
              v-model="payload.name"
              label="Event Name"
              :rules="[
                (v) => !!v || 'This field is required',
              ]"
              outlined
            />
          </v-col>
          <v-col>
            <v-text-field
              :value="_.kebabCase(payload.name)"
              label="Event Slug"
              outlined
              readonly
              disabled
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-switch
              v-model="payload.is_private"
              label="Private"
            >
              Create
            </v-switch>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-btn
              color="green"
              :loading="loading.create"
              :disabled="loading.create || !formValid"
              depressed
              @click="createMutation"
            >
              Create
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CreateEventGeneral',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    formValid: false,
    payload: {
      name: '',
      is_private: false
    },
    loading: {
      create: false
    }
  }),
  watch: {
    value: function (newValue) {
      if (newValue) {
        this.validateForm()
      }
    }
  },
  created () {
    this.validateForm()
  },
  methods: {
    validateForm () {
      this.$nextTick(function () {
        if (this.$refs.form) this.$refs.form.validate()
      })
    },
    closeDialog () {
      this.$emit('input', false)
    },
    async createMutation () {
      this.loading.create = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/createEvent').default,
          variables: { value: this.payload },
          update: (store, { data: { createEvent } }) => {
            const data = store.readQuery({ query: require('@/gql/getEvents').default })
            data.events = this._.unionBy(data.events, [createEvent], 'id')
            store.writeQuery({ query: require('@/gql/getEvents').default, data })
          }
        })
        this.payload = {
          name: '',
          is_private: false
        }
        this.closeDialog()
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'GraphQL Error',
          text: error.message.replace('GraphQL error: ', ''),
          duration: 10000
        })
      }
      this.loading.create = false
    }
  }
}
</script>
