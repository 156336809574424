import gql from 'graphql-tag'
import CommentCategoryAllFields from './fragments/CommentCategoryAllFields'

export default gql`
  mutation createEventCommentCategory ($event_id: Int!, $value: EventCommentCategoryCreateInput!) {
    createEventCommentCategory (event_id: $event_id, value: $value) {
      ...CommentCategoryAllFields
    }
  }
  ${CommentCategoryAllFields}
`
