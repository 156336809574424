import gql from 'graphql-tag'
import EventAllFields from './fragments/EventAllFields'

export default gql`
  mutation updateEvent ($id: Int!, $value: EventInput!) {
    updateEvent (id: $id, value: $value) {
      ...EventAllFields
    }
  }
  ${EventAllFields}
`
